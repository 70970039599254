@charset "UTF-8";
* {
  margin: 0;
  padding: 0; }

.site {
  background: url(../images/bg_pattern_gris.jpg) top center fixed #ccc;
  margin: 0 auto !important;
  width: 100%; }

.bouton-blanc {
  color: white;
  background-color: #20559f;
  border: 2px solid #20559f; }

h1 {
  font-family: "Lato", sans-serif !important; }

.noir {
  color: #1d1d1b !important; }

header #lien_home_header:hover {
  text-decoration: none; }
  header #lien_home_header:hover:focus {
    text-decoration: none; }

header .border_top {
  background: url(../images/border_header.jpg) no-repeat top center;
  height: 4px;
  max-width: 100%; }

.upper-header {
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  z-index: 4; }
  .upper-header a {
    color: white;
    text-decoration: none !important; }
    .upper-header a .facebook-link {
      display: inline-block;
      background-color: #36629e;
      border-radius: 50%;
      padding: 5px 11px;
      margin-right: 14px; }
      .upper-header a .facebook-link i {
        text-decoration: none; }
  .upper-header .date {
    float: left;
    padding: 13px 15px;
    color: #1d1d1b;
    text-transform: uppercase;
    font-size: 16px;
    display: block;
    font-weight: bold;
    font-family: "Lato", sans-serif; }
  .upper-header .do-sinscrire {
    float: left;
    background-color: #20559f;
    padding: 10px 30px; }
    .upper-header .do-sinscrire a {
      text-transform: uppercase;
      font-size: 18px;
      font-weight: bolder;
      font-family: "Lato", sans-serif;
      text-decoration: none;
      color: white; }
  .upper-header .do-a-don {
    float: left;
    background-color: #ffdd0b;
    padding: 10px 30px; }
    .upper-header .do-a-don a {
      text-transform: uppercase;
      font-size: 18px;
      font-weight: bolder;
      font-family: "Lato", sans-serif;
      text-decoration: none;
      color: #1d1d1b; }

.spacing-header {
  padding-bottom: 17px;
  padding-top: 17px; }

.boxflex {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .boxflex div {
    flex: 1; }

.menu-gauche {
  flex: 2 !important; }
  .menu-gauche ul {
    list-style: none;
    text-decoration: none;
    display: flex;
    justify-content: space-between; }
    .menu-gauche ul li {
      flex: 1; }
      .menu-gauche ul li a {
        font-family: "Lato", sans-serif;
        text-transform: uppercase;
        color: #1d1d1b;
        font-weight: bolder;
        font-size: 18px; }
        .menu-gauche ul li a:hover {
          text-decoration: none;
          color: #20559f; }

.menu-droite {
  flex: 2 !important; }
  .menu-droite ul {
    list-style: none;
    text-decoration: none;
    display: flex;
    justify-content: space-between; }
    .menu-droite ul li {
      flex: 1; }
      .menu-droite ul li a {
        font-family: "Lato", sans-serif;
        text-transform: uppercase;
        color: #1d1d1b;
        font-weight: bolder;
        font-size: 18px; }
        .menu-droite ul li a:hover {
          text-decoration: none;
          color: #20559f; }

.bandeau-evenements {
  background-color: #20559f;
  text-align: center;
  padding: 2em 0; }
  .bandeau-evenements h4 {
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 1em;
    font-size: 28px; }
  .bandeau-evenements a {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bolder;
    font-family: "Lato", sans-serif;
    text-decoration: none;
    color: #1d1d1b;
    background-color: #ffdd0b;
    padding: 11px 25px;
    border-radius: 12px;
    border: 1px solid #ffdd0b; }

/******** Footer ********/
.fond-footer {
  background-image: url("../images/fond-footer.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 20px; }

.listing-footer {
  list-style: none;
  margin-top: 54px;
  margin-bottom: 54px;
  margin-left: 0;
  display: flex; }
  .listing-footer li {
    display: inline-block;
    position: relative; }
    .listing-footer li .facebook-link {
      display: inline-block;
      background-color: #ffdd0b;
      border-radius: 50%;
      padding: 5px 11px;
      margin-right: 18px; }
    .listing-footer li a {
      text-decoration: none;
      color: white;
      text-transform: uppercase;
      font-family: "Lato", sans-serif;
      font-size: 16px;
      margin-right: 20px;
      -webkit-transition: color 0.5s;
      transition: color 0.5s; }
      .listing-footer li a:hover {
        color: #fbd22e;
        -webkit-transition: color 0.5s;
        transition: color 0.5s; }
    .listing-footer li:last-child a {
      margin-right: 0; }
    .listing-footer li ul {
      margin: 15px 0 0 0; }
      .listing-footer li ul li {
        display: block; }
        .listing-footer li ul li a {
          font-size: 14px;
          text-transform: none;
          font-weight: 400;
          color: #fff;
          display: block;
          line-height: 28px;
          text-align: left; }
  .listing-footer .menu-menu-footer-container #menu-menu-footer {
    display: flex;
    margin-left: 0 !important; }

.envelope a i {
  color: white;
  font-size: 18px;
  position: relative;
  margin-left: 10px;
  -webkit-transition: color 0.5s;
  transition: color 0.5s; }

.envelope:hover a i {
  color: #20559f;
  -webkit-transition: color 0.5s;
  transition: color 0.5s; }

.footer-droit {
  margin-top: 40px;
  margin-bottom: 54px; }
  .footer-droit ul {
    margin-left: 0; }
    .footer-droit ul li {
      display: inline-block; }
      .footer-droit ul li:first-child {
        margin-right: 15px; }
      .footer-droit ul li a {
        color: white;
        font-size: 28px;
        font-family: "Lato", sans-serif;
        text-decoration: none;
        -webkit-transition: color 0.5s;
        transition: color 0.5s; }
        .footer-droit ul li a:hover {
          color: #ffdd0b;
          -webkit-transition: color 0.5s;
          transition: color 0.5s; }
  .footer-droit .social-footer {
    margin-top: 17px; }
    .footer-droit .social-footer ul li {
      margin-right: 10px; }
      .footer-droit .social-footer ul li a {
        width: 40px;
        height: 40px;
        border-radius: 35px;
        text-decoration: none;
        display: block;
        position: relative; }
        .footer-droit .social-footer ul li a i {
          color: white;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate3d(-50%, -50%, 0); }
      .footer-droit .social-footer ul li .footer-facebook {
        background-color: #36629e; }
      .footer-droit .social-footer ul li .footer-twitter {
        background-color: #55acee; }
      .footer-droit .social-footer ul li .footer-instagram {
        background-color: #6a453b; }
      .footer-droit .social-footer ul li:last-child {
        margin-right: 0; }

.bas-footer {
  padding: 12px 0;
  background-color: #1d1d1b;
  color: white;
  font-size: 12px;
  font-family: "Lato", sans-serif; }
  .bas-footer a {
    color: white;
    text-decoration: none;
    -webkit-transition: color 0.5s;
    transition: color 0.5s; }
    .bas-footer a:hover {
      color: #ffdd0b;
      text-decoration: none;
      -webkit-transition: color 0.5s;
      transition: color 0.5s; }
  .bas-footer .webit-link:hover {
    color: #ffdd0b;
    -webkit-transition: color 0.5s;
    transition: color 0.5s; }

body::after,
body::before {
  display: none !important; }

a:focus {
  color: #ffdd0b; }

.fond-blanc {
  background-color: white; }

.fond-jaune {
  background-color: #ffdd0b; }

.fond-accueil {
  width: 100%;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat; }

.spacing-header {
  margin-top: 70px; }

.accueil {
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center; }
  .accueil .bloctxt {
    padding: 180px 0; }
  .accueil h1 {
    color: white;
    font-size: 60px;
    font-weight: 900;
    letter-spacing: 2px; }
  .accueil h2 {
    color: #ffdd0b;
    font-size: 34px;
    max-width: 55%;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.5em; }
  .accueil a {
    font-weight: bolder;
    color: white;
    text-decoration: none;
    background-color: transparent;
    font-size: 18px;
    padding: 11px 25px;
    display: inline-block;
    margin-top: 30px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;
    border: 2px solid #20559f; }
    .accueil a:hover {
      color: #ffdd0b !important; }

.pourquoi {
  font-family: "Lato", sans-serif;
  padding: 100px 0; }
  .pourquoi h2 {
    color: #20559f;
    text-transform: uppercase;
    font-size: 28px;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 30px;
    line-height: 1.3;
    padding-right: 100px; }
  .pourquoi p {
    color: #1d1d1b;
    font-size: 16px; }

.btn-pourquoi {
  margin-top: 60px;
  margin-bottom: 60px; }
  .btn-pourquoi h2 {
    text-transform: uppercase;
    min-height: 100px;
    font-weight: bold; }
  .btn-pourquoi p {
    font-weight: bold;
    font-size: 16px; }
  .btn-pourquoi .btn-iti {
    margin-right: 30px; }
  .btn-pourquoi a {
    padding: 10px 15px;
    display: inline-block;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
    margin-top: 40px; }
  .btn-pourquoi .bouton-blanc {
    color: white;
    background-color: #20559f;
    border: 2px solid #20559f; }
  .btn-pourquoi .bouton-bleu {
    color: #20559f;
    background-color: transparent;
    border: 2px solid #20559f; }
  .btn-pourquoi .bouton-jaune {
    color: black;
    background-color: #ffdd0b;
    border: 2px solid #ffdd0b; }

.partenaires {
  font-family: "Lato", sans-serif;
  padding-bottom: 60px; }
  .partenaires h2 {
    color: #20559f;
    text-transform: uppercase;
    font-size: 28px;
    font-weight: bolder;
    margin-top: 60px;
    margin-bottom: 30px; }
  .partenaires .slider-logo {
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center; }
    .partenaires .slider-logo img {
      margin: 0 auto;
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
      opacity: 0.4;
      -webkit-transition: all 0.3s;
      transition: all 0.3s; }
      .partenaires .slider-logo img:hover {
        -webkit-filter: grayscale(0%);
        filter: grayscale(0%);
        opacity: 1;
        -webkit-transition: all 0.3s;
        transition: all 0.3s; }
    .partenaires .slider-logo h4 {
      font-weight: bold;
      font-size: 16px;
      width: 85%;
      margin-left: auto;
      margin-right: auto; }
  .partenaires div a {
    padding: 10px 15px;
    display: inline-block;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px; }
  .partenaires div .bouton-blanc {
    color: white;
    background-color: #20559f;
    border: 2px solid #20559f; }

.slider-sidebar img {
  -webkit-filter: grayscale(100%);
  /* Chrome, Safari, Opera */
  filter: grayscale(100%);
  opacity: 0.4;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }
  .slider-sidebar img:hover {
    -webkit-filter: grayscale(0%);
    /* Chrome, Safari, Opera */
    filter: grayscale(0%);
    opacity: 1;
    -webkit-transition: all 0.3s;
    transition: all 0.3s; }

.don {
  padding-bottom: 60px; }
  .don h3 {
    color: #1d1d1b;
    text-transform: uppercase;
    font-size: 28px;
    font-weight: bolder;
    margin-top: 60px;
    margin-bottom: 30px;
    font-family: "Lato", sans-serif; }
  .don a {
    padding: 10px 15px;
    display: inline-block;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px; }
  .don .bouton-noir {
    color: #1d1d1b;
    background-color: transparent;
    border: 2px solid #1d1d1b;
    font-family: "Lato", sans-serif; }

.container-objectif {
  background-size: cover;
  background-position: top center;
  margin-bottom: 100px;
  min-height: 275px; }

.titre-objectif {
  color: white;
  font-size: 28px;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 60px; }

.conteneur-details {
  max-width: 415px;
  margin: 0 auto; }
  .conteneur-details .listing-details {
    margin-bottom: 35px; }
    .conteneur-details .listing-details p {
      color: #848484;
      font-family: "Lato", sans-serif;
      font-size: 16px;
      margin: 0;
      text-align: left !important; }
      .conteneur-details .listing-details p span {
        margin-bottom: 10px;
        font-size: 30px;
        color: #b0b0b0;
        font-weight: bolder; }

.titre-pv {
  color: #20559f;
  font-size: 28px;
  font-family: "Lato", sans-serif;
  margin-top: 60px;
  margin-bottom: 30px;
  font-weight: bold;
  text-transform: uppercase; }

.slider-photos-videos {
  margin-bottom: 60px; }
  .slider-photos-videos a .in-slider-pv {
    height: 163px;
    width: 240px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center; }

.btn-pv {
  margin-bottom: 60px; }
  .btn-pv a {
    padding: 10px 15px;
    display: inline-block;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px; }
  .btn-pv .bouton-blanc {
    color: white;
    background-color: #20559f;
    border: 2px solid #20559f; }

.reseaux-banner {
  color: #fff !important;
  text-align: center;
  margin: 0 0 8px 0;
  text-transform: uppercase;
  font-family: "Lato", sans-serif;
  overflow: hidden;
  width: 100%;
  max-width: 390px;
  margin: 0 auto;
  display: block; }
  .reseaux-banner a {
    float: left; }
  .reseaux-banner i {
    padding-right: 28px; }

.reseaux-banner .btn-facebook span {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  position: absolute;
  height: 100%;
  top: 0;
  left: 28%; }

.reseaux-banner .btn-twitter span {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  position: absolute;
  height: 100%;
  top: 0;
  left: 34%; }

.reseaux-banner .btn-mail span {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  position: absolute;
  height: 100%;
  top: 0;
  left: 32%; }

.btn-facebook {
  position: relative;
  color: #fff;
  padding: 10px 10px;
  background-color: #3b5998;
  width: 130px; }
  .btn-facebook:hover {
    color: #fff;
    text-decoration: none; }
  .btn-facebook:visited {
    color: #fff;
    text-decoration: none; }

.btn-twitter {
  position: relative;
  color: #fff;
  padding: 10px 12px;
  background-color: #1da1f2;
  width: 130px; }
  .btn-twitter:hover {
    color: #fff;
    text-decoration: none; }
  .btn-twitter:visited {
    color: #fff;
    text-decoration: none; }

.btn-mail {
  position: relative;
  color: #fff;
  padding: 10px 10px;
  background-color: #b0b0b0;
  width: 130px; }
  .btn-mail:hover {
    color: #fff;
    text-decoration: none; }
  .btn-mail:visited {
    color: #fff;
    text-decoration: none; }

.fond-defis {
  text-align: center;
  padding: 100px 0;
  position: relative; }
  .fond-defis h1 {
    font-size: 40px;
    color: #fff;
    text-transform: uppercase; }
  .fond-defis h2 {
    font-size: 26px;
    color: #ffdd0b;
    max-width: 50%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 70px; }
  .fond-defis .imageround {
    background-color: white;
    border-radius: 49%;
    -webkit-border-radius: 49%;
    -moz-border-radius: 49%;
    border: 50px solid white;
    width: 300px;
    position: absolute;
    bottom: -130px;
    left: 0;
    right: 0;
    margin: 0 auto; }

.page-defis h2 {
  text-transform: uppercase;
  font-weight: 700;
  color: black;
  margin-top: 0;
  font-size: 26px;
  text-align: center; }

.page-defis h3 {
  font-size: 18px;
  text-align: center;
  margin: 0 0 20px 0;
  font-weight: 700; }

.page-defis p {
  font-size: 16px;
  margin: 1.5em 0; }

.intro-defis {
  padding: 150px 0 100px 0; }
  .intro-defis h2 {
    text-align: left; }

.profit {
  padding: 75px 0; }
  .profit .nomargintop {
    margin-top: 0; }
  .profit .montant {
    color: #2c4a94;
    font-size: 70px;
    font-weight: 700;
    margin-top: 0.75em; }

.momentscles {
  padding: 75px 0; }
  .momentscles .boxmoments {
    margin-top: 2em; }
  .momentscles .conteneur-moment-cle {
    clear: both;
    display: flex;
    align-items: center;
    margin-bottom: 5em; }
    .momentscles .conteneur-moment-cle:nth-child(2n) .image-moment-cle {
      order: 2; }
    .momentscles .conteneur-moment-cle:nth-child(2n) .texte-moment-cle {
      order: 1; }
    .momentscles .conteneur-moment-cle h3 {
      text-align: left;
      margin-bottom: 0.5em; }
    .momentscles .conteneur-moment-cle p {
      margin: 1em 0; }

.fond-deroulement {
  background-image: url("../images/bg-deroulement.jpg");
  background-size: cover;
  background-position: top center;
  padding: 75px 0; }
  .fond-deroulement h2 {
    color: white;
    text-align: left; }
  .fond-deroulement p {
    color: white; }

.merciparticipants {
  padding: 100px 0;
  text-align: center; }
  .merciparticipants img {
    margin: 2em auto 1em auto; }

/***************** Animation *****************/
.bouton-blanc,
.bouton-bleu,
.bouton-noir {
  -webkit-transition: all 0.7s;
  transition: all 0.7s; }

.bouton-blanc:hover {
  background-color: transparent !important;
  color: #20559f !important;
  text-decoration: none;
  -webkit-transition: all 0.7s;
  transition: all 0.7s; }

.bouton-noir:hover {
  background-color: #1d1d1b;
  color: white;
  text-decoration: none;
  -webkit-transition: all 0.7s;
  transition: all 0.7s; }

.bouton-bleu:hover {
  background-color: #20559f;
  color: white;
  text-decoration: none;
  -webkit-transition: all 0.7s;
  transition: all 0.7s; }

.bouton-jaune:hover {
  background-color: transparent !important;
  color: #ffdd0b !important;
  text-decoration: none;
  -webkit-transition: all 0.7s;
  transition: all 0.7s; }

/***************** THERMOMETRE****************/
.conteneur {
  position: relative;
  height: 100px;
  float: right;
  width: 100%;
  max-width: 615px; }

.top-objectif {
  margin-top: 68px;
  margin-bottom: 150px; }

.dansThermo {
  position: absolute;
  right: 51px;
  top: 33px;
  text-align: center;
  z-index: 5;
  font-size: 30px;
  font-family: "Lato", sans-serif; }

.dansThermo h2 {
  color: white;
  text-transform: uppercase;
  margin-bottom: 0; }

.dansThermo p {
  color: #ffde0b;
  font-weight: 600; }

.objectif {
  font-family: "Lato", sans-serif;
  font-weight: 700; }

.objectif h2 {
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 0;
  color: white;
  font-size: 28px; }

.objectif p {
  color: #ffdd0b;
  font-size: 26px; }

.gris {
  top: 65px;
  background-color: #221f20;
  padding: 10px 5px;
  height: 48px;
  width: 63%;
  position: absolute;
  left: 2%;
  z-index: 2; }

.jaune {
  top: 66px;
  background-color: #ffdd0b;
  padding: 10px 5px;
  height: 44px;
  max-width: 70%;
  position: absolute;
  left: 10px;
  z-index: 3; }

.conteneur .fleche-down {
  position: absolute;
  z-index: 1;
  top: 80px; }

.fleche-down {
  opacity: 0; }

.fleche-down .fa-long-arrow-down {
  font-size: 45px;
  color: #ffdd0b; }

.fleche-down p {
  color: white;
  font-family: "Lato", sans-serif;
  font-size: 30px;
  font-weight: 600; }

.thermo {
  position: absolute;
  z-index: 4;
  max-width: 523px; }

.donnees {
  padding-bottom: 60px; }

.donnees h3,
p {
  font-family: "Lato", sans-serif !important; }

.donnees div {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.donnees h3 {
  font-weight: 700;
  font-size: 30px;
  text-decoration: none !important; }

.donnees p {
  font-size: 11px; }

.fondJaune {
  background-color: #fbd22e;
  font-family: "Lato", sans-serif; }

.fondJaune .fondJaune-conteneur .chaque {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  padding-top: 60px; }

.fondJaune .fondJaune-conteneur p {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  padding-top: 40px;
  padding-bottom: 25px; }

.fondJaune .fondJaune-conteneur a {
  color: white;
  background-color: #174c93;
  padding: 15px 20px;
  font-size: 20px;
  font-weight: 700;
  margin-top: 48px;
  margin-bottom: 48px;
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  border-radius: 8px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s; }

.fondJaune .fondJaune-conteneur a:hover {
  text-decoration: none;
  background: #353535;
  transition: all 0.5s;
  -webkit-transition: all 0.5s; }

/** Listing Équipe **/
.conteneur-membre {
  border-top: 2px solid #20559f;
  border-right: 2px solid #20559f;
  border-bottom: 2px solid #20559f;
  border-left: 2px solid #20559f;
  padding: 25px;
  font-family: "Lato", sans-serif;
  position: relative; }
  .conteneur-membre a {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #20559f;
    z-index: 2;
    width: 100%;
    display: block;
    height: 100%;
    opacity: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s; }
    .conteneur-membre a .hover-membre {
      position: absolute;
      top: 60%;
      left: 50%;
      -webkit-transform: translate3d(-50%, -50%, 0);
      transform: translate3d(-50%, -50%, 0); }
      .conteneur-membre a .hover-membre p {
        color: white;
        font-size: 18px; }
      .conteneur-membre a .hover-membre .plus-profil {
        width: 70px;
        height: 70px;
        position: relative;
        margin-bottom: 28px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%); }
        .conteneur-membre a .hover-membre .plus-profil .bar-horizon,
        .conteneur-membre a .hover-membre .plus-profil .bar-vertical {
          background-color: white;
          position: absolute; }
        .conteneur-membre a .hover-membre .plus-profil .bar-horizon {
          width: 100%;
          height: 2px;
          z-index: 3;
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }
        .conteneur-membre a .hover-membre .plus-profil .bar-vertical {
          width: 2px;
          height: 100%;
          z-index: 4;
          left: 50%;
          -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }
  .conteneur-membre h3 {
    font-size: 18px;
    margin-bottom: 3px;
    text-transform: uppercase;
    font-weight: bolder;
    color: #20559f; }
  .conteneur-membre p {
    margin-top: 0;
    margin-bottom: 15px;
    font-weight: bold;
    color: #b0b0b0;
    font-size: 16px; }
  .conteneur-membre span {
    font-size: 18px;
    margin-top: 20px;
    font-weight: bold;
    color: #20559f;
    display: inline-block; }
  .conteneur-membre:hover {
    border-bottom: 2px solid white; }
    .conteneur-membre:hover a {
      opacity: 1;
      -webkit-transition: all 0.5s;
      transition: all 0.5s; }
  .conteneur-membre .image-membre {
    width: 100px;
    height: 100px;
    display: block;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat; }

.bouton-support {
  width: 100%;
  display: block;
  text-align: center;
  font-size: 18px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 2px solid #20559f;
  border-right: 2px solid #20559f;
  border-bottom: 2px solid #20559f;
  border-left: 2px solid #20559f; }
  .bouton-support:hover {
    border-top: 2px solid white; }

.titre-participant {
  font-size: 28px;
  font-weight: bolder;
  color: #20559f;
  padding-left: 15px;
  font-family: "Lato", sans-serif;
  margin-bottom: 30px;
  text-transform: uppercase; }

#breadcrumbs {
  margin-top: 38px;
  margin-bottom: 38px;
  display: inline-block;
  color: #20559f;
  font-size: 16px; }

.single-image-membre {
  height: 390px !important;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }

.slider-image-membre {
  height: 83px !important;
  width: 83px !important;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 9px;
  margin-right: 9px;
  background-position: center center; }
  .slider-image-membre:last-child {
    margin-right: 0; }

.container-equipe-single {
  padding-bottom: 115px; }
  .container-equipe-single .slick-track {
    transform: none !important; }
  .container-equipe-single .slick-list.draggable {
    padding: 0 !important; }

.conteneur-content h2 {
  margin-top: 0;
  color: #20559f;
  font-family: "Lato", sans-serif;
  font-size: 18px;
  text-transform: uppercase; }

.conteneur-content .ville {
  color: #1d1d1b;
  font-family: "Lato", sans-serif;
  font-size: 16px; }

.conteneur-content .texte-content p {
  color: #848484;
  font-family: "Lato", sans-serif !important;
  font-size: 16px; }

.conteneur-content .objectif-content {
  color: #20559f;
  font-weight: 500;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 26px; }

.conteneur-content .bouton-blanc {
  display: inline-block;
  padding: 20px 30px;
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  font-size: 18px;
  border-radius: 12px;
  margin-bottom: 50px; }

.single-media a {
  display: block;
  float: left;
  text-decoration: none; }
  .single-media a .logo-media {
    width: 30px;
    height: 30px;
    text-align: center;
    display: inline-block; }
    .single-media a .logo-media i {
      padding-top: 8px;
      color: white; }
  .single-media a .texte-media {
    padding: 0 12px 0 10px;
    color: white;
    display: inline-block; }
  .single-media a:hover {
    text-decoration: none; }

.single-media .media-facebook {
  background-color: #36629e;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }
  .single-media .media-facebook .logo-media {
    border-right: 1px solid #334d86; }
  .single-media .media-facebook:hover {
    background-color: #334d86;
    -webkit-transition: all 0.3s;
    transition: all 0.3s; }

.single-media .media-twitter {
  background-color: #1da1f2;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }
  .single-media .media-twitter .logo-media {
    border-right: 1px solid #1896e4; }
  .single-media .media-twitter:hover {
    background-color: #1896e4;
    -webkit-transition: all 0.3s;
    transition: all 0.3s; }

.single-media .media-mail {
  background-color: #b0b0b0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }
  .single-media .media-mail .logo-media {
    border-right: 1px solid #9a9a9a; }
  .single-media .media-mail:hover {
    background-color: #9a9a9a;
    -webkit-transition: all 0.3s;
    transition: all 0.3s; }

/** LE DÉFI **/
.conteneur-defi {
  font-family: "Lato", sans-serif; }
  .conteneur-defi h1,
  .conteneur-defi h2,
  .conteneur-defi h3,
  .conteneur-defi h4,
  .conteneur-defi h5 {
    color: #20559f;
    text-transform: uppercase; }
  .conteneur-defi h1 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 30px; }
  .conteneur-defi h2,
  .conteneur-defi h3,
  .conteneur-defi h4,
  .conteneur-defi h5 {
    font-size: 18px; }
  .conteneur-defi p,
  .conteneur-defi span {
    font-size: 16px;
    color: #848484 !important; }
  .conteneur-defi img {
    width: 100%;
    display: block;
    margin-top: 30px;
    margin-bottom: 30px; }
  .conteneur-defi a {
    display: inline-block;
    color: white;
    background-color: #20559f;
    border: 2px solid #20559f;
    -webkit-transition: all 0.7s;
    transition: all 0.7s;
    padding: 20px;
    border-radius: 12px;
    text-transform: uppercase;
    margin-bottom: 58px; }
    .conteneur-defi a:hover {
      background-color: transparent;
      color: #20559f;
      text-decoration: none;
      -webkit-transition: all 0.7s;
      transition: all 0.7s; }
  .conteneur-defi iframe {
    max-width: 100%;
    max-height: 375px; }

/** L'ITINÉRAIRE **/
.conteneur-itineraire {
  padding-bottom: 60px;
  font-family: "Lato", sans-serif; }
  .conteneur-itineraire h1,
  .conteneur-itineraire h2,
  .conteneur-itineraire h3,
  .conteneur-itineraire h4,
  .conteneur-itineraire h5 {
    color: #20559f;
    text-transform: uppercase; }
  .conteneur-itineraire h1 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 30px; }
  .conteneur-itineraire h2,
  .conteneur-itineraire h3,
  .conteneur-itineraire h4,
  .conteneur-itineraire h5 {
    font-size: 18px; }
  .conteneur-itineraire p {
    font-size: 16px;
    color: #848484;
    font-family: "Lato", sans-serif; }
    .conteneur-itineraire p em {
      color: #20559f;
      text-transform: uppercase;
      font-size: 14px;
      text-decoration: none;
      font-style: normal; }
      .conteneur-itineraire p em strong {
        font-size: 18px; }
  .conteneur-itineraire img {
    width: 100%;
    display: block;
    margin-top: 30px;
    margin-bottom: 30px; }
  .conteneur-itineraire a {
    display: inline-block;
    color: white;
    background-color: #20559f;
    border: 2px solid #20559f;
    -webkit-transition: all 0.7s;
    transition: all 0.7s;
    padding: 20px;
    border-radius: 12px;
    text-transform: uppercase;
    margin-bottom: 58px; }
    .conteneur-itineraire a:hover {
      background-color: transparent;
      color: #20559f;
      text-decoration: none;
      -webkit-transition: all 0.7s;
      transition: all 0.7s; }
  .conteneur-itineraire iframe {
    max-width: 100%;
    max-height: 375px; }

/** PHOTOS **/
.titre-photo-page {
  color: #20559f;
  text-transform: uppercase; }

.conteneur-photo-page {
  padding-bottom: 80px; }

/** FICHE TECHNIQUE **/
.conteneur-fiche {
  width: 100%; }
  .conteneur-fiche h1 {
    text-transform: uppercase;
    color: #20559f;
    font-weight: bold;
    margin-bottom: 27px;
    text-align: left;
    font-size: 28px;
    font-family: "Lato", sans-serif; }
  .conteneur-fiche h2 {
    text-transform: uppercase;
    color: #848484;
    margin-bottom: 20px;
    font-family: "Lato", sans-serif; }
  .conteneur-fiche .conteneur-contenu {
    width: 100%;
    margin-bottom: 30px; }
    .conteneur-fiche .conteneur-contenu .bar-bleu,
    .conteneur-fiche .conteneur-contenu .conteneur-contenu {
      display: block;
      width: 100%; }
    .conteneur-fiche .conteneur-contenu .bar-bleu {
      background-color: #20559f;
      cursor: pointer;
      position: relative; }
      .conteneur-fiche .conteneur-contenu .bar-bleu p {
        color: white;
        font-family: "Lato", sans-serif;
        font-size: 18px;
        display: block;
        font-weight: bold;
        padding: 11px 0 11px 10px;
        margin-bottom: 0; }
      .conteneur-fiche .conteneur-contenu .bar-bleu i {
        float: right;
        position: absolute;
        display: block;
        bottom: 17px;
        right: 15px;
        color: white; }
      .conteneur-fiche .conteneur-contenu .bar-bleu .fa-minus {
        display: none; }
      .conteneur-fiche .conteneur-contenu .bar-bleu .fa-plus {
        display: block; }
    .conteneur-fiche .conteneur-contenu .conteneur-gris {
      background-color: #ebebeb;
      padding: 11px 165px 11px 10px; }
      .conteneur-fiche .conteneur-contenu .conteneur-gris p,
      .conteneur-fiche .conteneur-contenu .conteneur-gris ul li {
        color: #1d1d1b;
        font-size: 16px;
        font-family: "Lato", sans-serif; }
      .conteneur-fiche .conteneur-contenu .conteneur-gris a {
        color: #20559f;
        text-decoration: none;
        font-weight: bold; }
        .conteneur-fiche .conteneur-contenu .conteneur-gris a:hover {
          text-decoration: underline; }
    .conteneur-fiche .conteneur-contenu .faq-gris {
      display: none; }
  .conteneur-fiche .plus-info p {
    color: #20559f;
    margin-bottom: 60px; }
    .conteneur-fiche .plus-info p a {
      color: #20559f;
      text-decoration: underline; }
  .conteneur-fiche .bouton-lien {
    display: inline-block;
    text-align: center;
    padding: 20px 15px;
    text-transform: uppercase;
    border-radius: 12px;
    margin-bottom: 60px;
    font-size: 18px; }

/** FAQ **/
/** SIDEBAR **/
.conteneur-right-side {
  padding-top: 25px;
  padding-left: 45px !important; }
  .conteneur-right-side .bloc-right {
    display: block;
    margin-bottom: 30px;
    width: 100%;
    border: 1px solid #20559f;
    position: relative; }
    .conteneur-right-side .bloc-right .titre-bloc-right {
      position: absolute;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      top: -25px;
      color: #20559f;
      background-color: white;
      padding: 14px;
      text-transform: uppercase; }
    .conteneur-right-side .bloc-right .texte-bloc-right,
    .conteneur-right-side .bloc-right img {
      margin-top: 80px;
      margin-bottom: 80px;
      color: #848484;
      text-transform: uppercase;
      font-size: 18px;
      max-width: 337px;
      margin-left: auto;
      margin-right: auto; }
    .conteneur-right-side .bloc-right img {
      display: block; }
    .conteneur-right-side .bloc-right a {
      padding: 20px 38px;
      display: inline-block;
      margin: 0 auto 60px;
      width: 273px;
      border-radius: 12px;
      text-transform: uppercase; }
      .conteneur-right-side .bloc-right a:hover {
        background-color: transparent;
        color: #20559f;
        text-decoration: none;
        -webkit-transition: all 0.7s;
        transition: all 0.7s; }

.listing-partenaire {
  margin-bottom: 25px; }
  .listing-partenaire h2 {
    color: #20559f;
    font-size: 28px;
    text-transform: uppercase;
    margin: 45px 0 45px; }
  .listing-partenaire .image-partenaire {
    display: inline-block;
    width: 100%;
    max-width: 224px;
    margin-bottom: 35px; }

.lien-menu span {
  position: absolute;
  margin-top: 15px;
  margin-left: -15px;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 3px;
  /* Faire disparaire infobulle par défaut */
  /* On determine l'origine de la rotation */
  transform: scale(0);
  /* Faire durer l'effet */
  transition: all 0.25s;
  /* Effet sur la transparence */
  opacity: 0; }

/* Apparition de la bulle avec le scale à 1 */
.lien-menu:hover span,
.lien-menu:focus span {
  transform: scale(1);
  /* Effet sur la transparence */
  opacity: 1; }

/* CONTACT */
.conteneur-contact {
  font-family: "Lato", sans-serif; }
  .conteneur-contact h1,
  .conteneur-contact h2,
  .conteneur-contact h3,
  .conteneur-contact h4,
  .conteneur-contact h5 {
    color: #20559f;
    text-transform: uppercase; }
  .conteneur-contact h1 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 30px; }
  .conteneur-contact h2,
  .conteneur-contact h3,
  .conteneur-contact h4,
  .conteneur-contact h5 {
    font-size: 18px; }
  .conteneur-contact p {
    font-size: 16px;
    color: #1d1d1b !important;
    margin-bottom: 5px; }
  .conteneur-contact img {
    width: 100%;
    display: block;
    margin-top: 30px;
    margin-bottom: 30px; }
  .conteneur-contact iframe {
    max-width: 100%;
    max-height: 375px; }
  .conteneur-contact #adresse_contact {
    margin-bottom: 15px; }
    .conteneur-contact #adresse_contact p {
      margin-bottom: 0; }
  .conteneur-contact #tel_contact {
    color: #1d1d1b;
    font-weight: bold;
    font-size: 16px;
    text-decoration: none;
    display: block; }
  .conteneur-contact #lien_mail_contact {
    color: #20559f;
    font-size: 16px;
    margin-bottom: 20px;
    text-decoration: underline;
    display: block; }
  .conteneur-contact #plus_info_contact {
    font-size: 16px;
    display: block; }

.conteneur_form {
  color: #1d1d1b; }

.form h2 {
  font-family: "Lato", sans-serif;
  font-size: 18px;
  color: #20559f;
  text-transform: uppercase;
  font-weight: bold; }

.champ_text_conteneur_form {
  background-color: white;
  background: white !important;
  background-image: none;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: #ffdd0b 1px solid !important;
  width: 100%;
  max-width: 299px;
  display: inline;
  padding-bottom: 0 !important;
  border-radius: 0 !important; }

.champ_tel_conteneur_form {
  background-color: white;
  background: white !important;
  background-image: none;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: #ffdd0b 1px solid !important;
  width: 99%;
  max-width: 235px;
  display: inline;
  padding-bottom: 0 !important;
  border-radius: 0 !important; }

.champ_email_conteneur_form {
  background-color: white;
  background: white !important;
  background-image: none;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: #ffdd0b 1px solid !important;
  width: 99%;
  max-width: 435px;
  display: inline;
  padding-bottom: 0 !important;
  border-radius: 0 !important; }

.choix_form_conteneur_form {
  border: 1px solid #ffdd0b;
  width: 100%;
  max-width: 295px; }

.btn_submit input {
  margin-top: 30px;
  color: white;
  background-color: #20559f;
  border: 2px solid #20559f;
  display: inline-block;
  -webkit-transition: all 0.7s;
  transition: all 0.7s;
  border-radius: 12px;
  padding: 21px 25px; }

.btn_submit input:hover {
  background-color: transparent !important;
  color: #20559f !important;
  text-decoration: none;
  -webkit-transition: all 0.7s;
  transition: all 0.7s; }

.spacing_contact {
  padding-bottom: 100px; }

/* OVERLAY */
.overlay-inscription {
  position: fixed;
  z-index: 999;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  opacity: 0;
  display: none; }

.in-overlay-inscription {
  background-image: url("../images/fond-form.jpg");
  background-size: 100%;
  position: absolute;
  z-index: 1000;
  width: 500px;
  border: 2px solid #ffdd0b;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  padding: 30px;
  opacity: 0;
  display: none; }
  .in-overlay-inscription h2 {
    color: white;
    font-weight: 300; }
  .in-overlay-inscription .form .champ_text_conteneur_form {
    max-width: 297px; }
  .in-overlay-inscription .form .champ_tel_conteneur_form {
    max-width: 232px; }
  .in-overlay-inscription .form .conteneur_form p {
    color: white;
    font-weight: 300; }
  .in-overlay-inscription .form .champ_text_conteneur_form,
  .in-overlay-inscription .form .champ_tel_conteneur_form,
  .in-overlay-inscription .form .champ_email_conteneur_form {
    background: transparent !important;
    color: white; }
  .in-overlay-inscription .form .choix_form_conteneur_form {
    color: #1d1d1b; }
  .in-overlay-inscription .form .champ_email_conteneur_form {
    width: 99%; }
  .in-overlay-inscription .form .btn_submit input {
    border-color: #ffdd0b;
    background-color: transparent;
    color: white;
    font-weight: 300;
    border-radius: 0px;
    padding: 14px 15px; }
    .in-overlay-inscription .form .btn_submit input:hover {
      color: white !important;
      background-color: #ffdd0b !important; }
  .in-overlay-inscription .form .wpcf7-response-output {
    color: white; }

.overlay-inscription.showing,
.in-overlay-inscription.showing {
  opacity: 1;
  display: block;
  -webkit-transition: all 0.5s;
  transition: all 0.5s; }

.formulaire {
  margin-bottom: 30px; }
  .formulaire hr {
    margin-top: 10px;
    border: 1px solid #b7b7b7; }
  .formulaire input {
    background-color: #eeecec;
    border: 0;
    margin-bottom: 0px; }
    .formulaire input:focus {
      border: 2px solid #ffdd05;
      outline: 0; }
  .formulaire select {
    width: 100%;
    height: 41px;
    border: 0;
    background-color: #eeecec; }
    .formulaire select:focus {
      border: 2px solid #ffdd05;
      outline: 0; }
  .formulaire input[type="submit"] {
    display: block;
    margin: 40px auto 0;
    background-color: #20559f;
    border: 1px solid #20559f; }
    .formulaire input[type="submit"]:hover {
      background-color: white;
      color: #20559f; }
  .formulaire img {
    display: inline-block; }

@media screen and (max-width: 1200px) {
  .menu-gauche ul {
    margin-left: 0; }
    .menu-gauche ul li:last-child {
      padding: 0 35px; }
  .menu-droite ul {
    margin-left: 0; }
    .menu-droite ul li:first-child {
      padding: 0 35px; }
  .thermo {
    position: absolute;
    z-index: 4;
    max-width: 420px; }
  .dansThermo {
    position: absolute;
    right: 52px;
    top: 20px;
    text-align: center;
    z-index: 5;
    font-size: 23px;
    font-family: "Lato", sans-serif; }
    .dansThermo h2 {
      font-size: 23px; }
  .gris {
    top: 51px;
    background-color: #221f20;
    padding: 10px 5px;
    height: 38px;
    width: 62%;
    position: absolute;
    left: 2%;
    z-index: 2; }
  .jaune {
    top: 53px;
    background-color: #ffdd0b;
    padding: 10px 5px;
    height: 36px;
    max-width: 66%;
    position: absolute;
    left: 8px;
    z-index: 3; }
  .conteneur .fleche-down {
    position: absolute;
    z-index: 1;
    top: 50px; }
  .listing-partenaire .image-partenaire {
    max-width: 232px; } }

@media screen and (max-width: 1024px) {
  .logo-header {
    width: 120px; }
  .thermo {
    position: absolute;
    z-index: 4;
    max-width: 330px; }
  .dansThermo {
    position: absolute;
    right: 19px;
    top: 20px;
    text-align: center;
    z-index: 5;
    font-size: 16px;
    font-family: "Lato", sans-serif; }
    .dansThermo h2 {
      font-size: 16px; }
  .gris {
    top: 41px;
    background-color: #221f20;
    padding: 10px 5px;
    height: 28px;
    width: 62%;
    position: absolute;
    left: 2%;
    z-index: 2; }
  .jaune {
    top: 42px;
    background-color: #ffdd0b;
    padding: 10px 5px;
    height: 28px;
    max-width: 66%;
    position: absolute;
    left: 6px;
    z-index: 3; }
  .conteneur {
    max-width: 330px; }
    .conteneur .fleche-down {
      position: absolute;
      z-index: 1;
      top: 45px; }
  .conteneur-right-side .bloc-right {
    max-width: 426px;
    margin: 0 auto 30px; }
    .conteneur-right-side .bloc-right a {
      padding: 20px 38px;
      display: block;
      margin: 0 auto 60px;
      width: 204px;
      font-size: 11px;
      border-radius: 12px;
      text-transform: uppercase; }
  .conteneur-fiche .conteneur-contenu .conteneur-gris {
    padding: 11px 10px 11px 10px; }
  .conteneur-right-side {
    padding-left: 15px !important; }
  #menu-menu-footer {
    flex-direction: column; }
  .listing-footer li {
    padding-bottom: 10px; }
    .listing-footer li ul {
      margin-top: 5px; }
      .listing-footer li ul li {
        padding-bottom: 0; }
  .footer-droit ul li a {
    font-size: 18px; } }

@media screen and (max-width: 768px) {
  .conteneur {
    max-width: 290px;
    float: none;
    display: block;
    margin: 0 auto; }
    .conteneur .fleche-down {
      position: absolute;
      z-index: 1;
      top: 33px; }
  .accueil h2 {
    max-width: 80%; }
  .pourquoi {
    padding: 0; }
  .btn-pourquoi h2 {
    height: auto; }
  .thermo {
    max-width: 290px; }
  .dansThermo {
    position: absolute;
    right: 12px;
    top: 11px;
    text-align: center;
    z-index: 5;
    font-size: 16px;
    font-family: "Lato", sans-serif; }
  .gris {
    top: 37px;
    background-color: #221f20;
    padding: 10px 5px;
    height: 24px;
    width: 70%;
    position: absolute;
    left: 2%;
    z-index: 2; }
  .jaune {
    top: 37px;
    background-color: #ffdd0b;
    padding: 10px 5px;
    height: 24px;
    max-width: 66%;
    position: absolute;
    left: 5px;
    z-index: 3; }
  .conteneur-details {
    margin-top: 60px; }
  .conteneur-slider {
    margin-bottom: 50px; }
  .conteneur-content {
    margin-bottom: 100px; }
  .conteneur-right-side {
    margin-top: 40px; }
  .menu-mobile {
    width: 40px;
    height: 20px;
    position: relative;
    margin: 20px auto;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer; }
  .menu-mobile span {
    display: block;
    position: absolute;
    height: 7px;
    width: 100%;
    background: #000;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out; }
  .menu-mobile span:nth-child(1) {
    top: 0px; }
  .menu-mobile span:nth-child(2), .menu-mobile span:nth-child(3) {
    top: 12px; }
  .menu-mobile span:nth-child(4) {
    top: 25px; }
  .menu-mobile.open span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%; }
  .menu-mobile.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg); }
  .menu-mobile.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg); }
  .menu-mobile.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%; }
  .menu-responsive {
    z-index: 1000000;
    font-family: "Lato", sans-serif;
    font-size: 1em;
    width: 100%;
    background: #131313;
    position: absolute;
    text-align: center; }
  .menu-responsive ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    list-style-image: none; }
  .menu-responsive li {
    display: block;
    padding: 15px 0 15px 0;
    border-bottom: #1d1f20 1px solid; }
  .menu-responsive li:hover {
    display: block;
    background: #181818;
    padding: 15px 0 15px 0;
    border-bottom: #1d1f20 1px solid; }
  .menu-responsive ul li a {
    text-decoration: none;
    margin: 0px;
    color: #fff; }
  .menu-responsive ul li a:hover {
    color: #fff;
    text-decoration: none; }
  .menu-responsive a {
    text-decoration: none;
    color: white;
    display: block; }
  .menu-responsive a:hover {
    text-decoration: none;
    color: white; }
  .container-menu-responsive {
    display: none; }
  .header-mobile {
    margin: 10px 0 20px 0; }
  .date-menu-responsive {
    color: #fff; }
  .inscription-mobile {
    background-color: #2c4a94;
    padding: 15px !important;
    border-radius: 10px;
    margin: 10px auto;
    display: inline-block !important;
    width: 50%; }
  .donner-mobile {
    background-color: #ffdd0b;
    color: #000;
    padding: 15px !important;
    border-radius: 10px;
    margin: 10px auto;
    display: inline-block !important;
    width: 50%; }
  .intro-defis h2 {
    margin-top: 1.5em; }
  .conteneur-moment-cle {
    flex-wrap: wrap; }
    .conteneur-moment-cle .image-moment-cle {
      order: 2; }
    .conteneur-moment-cle .texte-moment-cle {
      order: 2; }
  .pourquoi h2 {
    font-size: 28px;
    margin-top: 40px;
    padding-right: 0; }
  .fond-defis h2 {
    max-width: 90%; } }

@media screen and (max-width: 414px) {
  .accueil h1 {
    font-size: 27px;
    padding-top: 80px; }
  .accueil h2 {
    max-width: 100%;
    font-size: 24px; }
  .accueil a {
    text-decoration: none;
    font-size: 17px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    padding: 4px 6px;
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 100px; }
  .btn-pourquoi a {
    padding: 4px 6px;
    font-size: 17px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px; }
  .btn-pourquoi .btn-iti {
    margin-right: 0;
    margin-bottom: 10px; }
  .partenaires {
    padding-bottom: 30px; }
    .partenaires h2 {
      font-size: 24px;
      margin-top: 30px; }
    .partenaires div a {
      font-size: 17px;
      -webkit-border-radius: 6px;
      -moz-border-radius: 6px;
      border-radius: 6px;
      padding: 4px 6px; }
  .don {
    padding-bottom: 30px; }
    .don h3 {
      font-size: 24px;
      margin-top: 30px;
      font-family: "Lato", sans-serif !important; }
    .don a {
      font-size: 17px;
      -webkit-border-radius: 6px;
      -moz-border-radius: 6px;
      border-radius: 6px;
      padding: 4px 6px; }
  .titre-pv {
    font-size: 24px;
    margin-top: 30px; }
  .btn-pv {
    margin-bottom: 30px; }
    .btn-pv a {
      font-size: 17px;
      -webkit-border-radius: 6px;
      -moz-border-radius: 6px;
      border-radius: 6px;
      padding: 4px 6px; }
  .listing-footer {
    text-align: left; }
    .listing-footer li {
      display: block;
      margin-top: 15px; }
      .listing-footer li a {
        margin-right: 0; }
      .listing-footer li .facebook-link {
        margin: 0; }
    .listing-footer #menu-menu-footer {
      flex-direction: column; }
  .footer-droit {
    margin-top: 68px;
    margin-bottom: 30px;
    text-align: left; }
    .footer-droit ul li:first-child {
      margin-right: 0; }
    .footer-droit ul li a {
      font-size: 16px; }
  .social-footer {
    text-align: right; }
  .bas-footer {
    text-align: center; }
  .slick-slide img {
    display: block;
    margin: 0 auto; }
  .slider-photos-videos a .in-slider-pv {
    height: 163px;
    width: 240px;
    background-size: cover;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-position: center center; }
  .titre-participant {
    margin-top: 0px; }
  .single-media a .logo-media {
    width: 28px;
    height: 28px; }
    .single-media a .logo-media i {
      padding-top: 7px; }
  .single-media a .texte-media {
    padding: 0 8px 0 8px;
    color: white;
    display: inline-block; }
  .single-image-membre {
    height: 300px !important; }
  .listing-partenaire .image-partenaire {
    max-width: 170px; }
  .listing-partenaire img {
    width: 90%; }
  .fond-deroulement {
    padding: 35px 0; }
  .profit .montant {
    font-size: 40px; }
  .reseaux-banner {
    color: #fff !important;
    text-align: center;
    margin: 0 auto;
    width: 130px;
    text-transform: uppercase;
    font-family: "Lato", sans-serif; }
    .reseaux-banner i {
      padding-right: 30px; }
  .reseaux-banner .btn-facebook span {
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    position: absolute;
    height: 100%;
    top: 0;
    left: 28%; }
  .reseaux-banner .btn-twitter span {
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    position: absolute;
    height: 100%;
    top: 0;
    left: 28%; }
  .reseaux-banner .btn-mail span {
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    position: absolute;
    height: 100%;
    top: 0;
    left: 28%; }
  .btn-facebook {
    display: block !important;
    margin: auto; }
  .btn-twitter {
    display: block !important;
    margin: auto; }
  .btn-mail {
    display: block !important;
    margin: auto; }
  .champ_email_conteneur_form {
    width: 98% !important; } }

@media screen and (max-width: 530px) {
  .in-overlay-inscription {
    width: 270px;
    padding: 15px; }
    .in-overlay-inscription .form h2 {
      font-size: 14px; }
    .in-overlay-inscription .form .conteneur_form p {
      margin-bottom: 0; }
    .in-overlay-inscription .form .champ_email_conteneur_form {
      width: 98%; }
    .in-overlay-inscription .form .btn_submit input {
      margin-top: 10px;
      padding: 12px 14px; } }

@media screen and (min-width: 500px) and (max-width: 768px) {
  .box-conteneur-membre {
    width: 50% !important; } }
